import React from 'react';
import { graphql } from 'gatsby';

import SEO from '@components/seo';

import Layout from '@components/Layout/Layout';
import TextArticle from '@src/components/TextArticle/TextArticle';
import Container from '@src/components/Container';

const Personuppgiftspolicy = ({ data }) => {
  const { headLine, pageContent } = data.contentfulPage;

  const bodyText = pageContent[1].content.content;

  return (
    <Layout>
      <SEO title={headLine} noIndex />
      <Container>
        <TextArticle style={{ marginTop: 88, marginBottom: 48 }}>
          {bodyText}
        </TextArticle>
      </Container>
    </Layout>
  );
};

export default Personuppgiftspolicy;

export const query = graphql`
  query PersonuppgiftsolicyQuery {
    contentfulPage(pageUrl: { eq: "/personuppgiftspolicy" }) {
      headLine
      pageHeader
      pageHeaderDescription {
        pageHeaderDescription
      }
      pageContactHeader
      pageContent {
        ... on ContentfulTextSection {
          title
          content {
            content
          }
        }
        ... on ContentfulDescriptionComponent {
          bodyText {
            bodyText
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`;
